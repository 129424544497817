<template>
  <div class="relative">
    <closeIcon class="cursor-pointer absolute top-0 -right-4" @close="close"/>

    <p class="text-2xl text-black font-bold mb-16 pt-3">Add {{ type }}s through CSV</p>
    <p class="text-base text-black mb-6">CSV file should contain {{ typeLower }} id’s or full URL’s</p>
    <form 
      @submit.prevent="matchYoutubeUrl"
      class="flex flex-wrap items-center justify-between"
    >
      <input 
        type="file"
        ref="CSVfile"
        class="hidden"
        @change="getFile"
      >
      <span
        @click="$refs.CSVfile.click()"
        class="relative flex items-center flex-1 mr-3 mb-4 base-bg-darker-300 youTubeUrl-input rounded px-4 h-8 font-mono text-sm text-black outline-none placeholder-black cursor-pointer"
      >
        <template v-if="file">
          {{ this.fileName }}
          <closeIcon class="cursor-pointer absolute top-1 right-0" @close="clearFile"/>
        </template>
        <template v-else>
          Select CSV File
        </template>
      </span>
      <button 
        type="submit"
        class="mb-4 flex-0 font-mono inline-block text-sm px-5 h-8 rounded-2xl purple-bg purple-light whitespace-nowrap"
      >
        Upload CSV
      </button>
    </form>
    <div class="w-full flex items-center pl-6 mb-6">
      <input 
        v-model="fileHasHeaders"
        id="fileHasHeaders" 
        name="fileHasHeaders"
        type="checkbox" 
        class="hidden checkboxGrey"
      >
      <label for="fileHasHeaders" class="labelGrey block cursor-pointer">
        <p class="text-base text-black ml-1">File has headers</p>
      </label>
    </div>

    <div 
      v-if="channels.length"
      class="channels-wrap rounded-lg border border-grey-darker-400 mb-4"
    >
      <div class="channels-head base-bg-darker-600 flex flex-wrap items-center px-4 py-2 rounded-lg rounded-b-none border-b border-grey-light-500">
        <span class="font-grey-darker-500 text-base mr-4">{{ type }} ID</span>
        <multiselect 
          v-model="channelID" 
          :options="[]"
          :searchable="false"
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
          placeholder=""
          class="max-w-xs font-sans bg-white border border-grey-light inline-block"
        />
      </div>
      <div class="channels-body px-4">
        <ul>
          <li 
            v-for="channel in channels"
            :key="channel.id"
            class="border-grey-light-600 border-b border-dashed py-4"
          >
            <div class="w-full flex items-center pl-6">
              <input 
                v-model="channel.valid"
                :id="fileHasHeaders" 
                :name="channel.id"
                disabled
                type="checkbox" 
                class="hidden checkboxGrey"
              >
              <label :for="channel.id" class="labelGrey block cursor-pointer flex items-center justify-between w-full">
                <p class="text-base base-font-gray-300 ml-1">{{ channel.channel_id }}</p>
                <span 
                  v-if="!channel.valid"
                  class="font-mono text-xs text-black"
                >
                  invalid format
                </span>
              </label>
            </div>
          </li>
        </ul>

        <ButtonWithLoader 
          :btnText="`Add ${ channels.length } ${ type }s`"
          bgClass="purple-bg"
          textClass="purple-light font-monoDemi text-sm"
          heigthClass="h-8"
          class="my-3"
          @nextStep="'addChannels'"
        />
      </div>
    </div>

    <faq />

  </div>
</template>

<script>
  import closeIcon from '@/components/icons/close-icon'
  import faq from '@/components/Bucket/faq'
  import ButtonWithLoader from '@/components/ButtonWithLoader'
  import { validateChannelID } from '@/helpers/validaions'
  import { warningToastConfig } from '@/helpers/constants'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      closeIcon,
      faq,
      ButtonWithLoader
    },
    props: [ 'type', 'typeLower' ],
    data(){
      return{
        file: null,
        fileName: '',
        fileHasHeaders: true,
        channelID: '',
        channels: [],
        loading: false
      }
    },
    computed: {
      ...mapGetters({
        bucket: 'bucket/bucket',
      })
    },
    methods: {
      addChannels(){
        this.loading = true
        let promises = []
        this.$auth.getTokenSilently()
        .then((res) => {
          this.channels.forEach(elem => {
            promises.push(this.$axios.post(`https://xy0qty1qh8.execute-api.us-west-1.amazonaws.com/api/v1/add-channel-bucket`,{
                  channelId: elem.channel_id,
                  bucketId: this.bucket.bucketId,
                  fullScrape: true
                },
                {
                  headers: {
                    Authorization: `Bearer ${res}`,
                  },
                }
              )
            )
          })

          Promise.all(promises)
          .then(() => {
            this.loading = false
          })
          .catch(err => {
            this.$toasted.show(err.response.data.response, warningToastConfig)
            this.loading = false
          })
        })
        .catch(err => {
          this.$toasted.show(err, warningToastConfig)
          this.loading = false
        })
      },
      matchYoutubeUrl(){
        this.$papa.parse(this.file, {
          delimiter: ",",
          newline: "",
          header: this.fileHasHeaders,
          dynamicTyping: true,
          complete: (result) => {
            this.channels = JSON.parse(JSON.stringify(result.data))
            this.channels.forEach(elem => {
              elem.valid = validateChannelID(elem.channel_id)
            })
          }
        })
      },
      close(){
        this.$emit('close')
      },
      getFile(event){
        const file = event.target.files[0]
        if(file){
          this.file = file
          this.fileName = this.file.name
        }
      },
      clearFile(event){
        event.stopPropagation();
        
        this.file = null
        this.fileName = ''
      }
    },
  }
</script>

<style lang="scss" scoped>
.labelGrey{
  &:before{
    top: .25rem;
  }
}
</style>